import React, { useState } from 'react'
import Map from '../Map'
import ContactFormStyles from '../../styles/ContactForm.module.sass'

/**
 * Display a functional contact form
 *
 * @param {string}  className
 */
const ContactForm = props => {
  const initialEmailSubjectString = 'HamperCompany Enquiry'

  const [emailSubject, setEmailSubject] = useState(initialEmailSubjectString)

  const handleSetEmailSubject = (customerName) => {
    if (customerName && customerName.trim().length) {
      setEmailSubject(initialEmailSubjectString + ' from ' + customerName)
    }
    else {
      setEmailSubject(initialEmailSubjectString)
    }
  }

  return (
    <div className={`is-clearfix contact-form-holder ${ContactFormStyles.contactFormHolder || ''}`}>
      <div className={`container is-semi-narrow ${ContactFormStyles.contactFormContainer || ''}`}>
        <div className={`form-container ${ContactFormStyles.form || ''} ${props.className || ''}`}>
          <div>
            <form
              name="HamperCompany Enquiry"
              method="post"
              action="/thanks/"
              data-netlify="true"
              data-netlify-honeypot="first-name"
            >
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type="hidden" name="form-name" value="HamperCompany Enquiry" />
              <input type="hidden" name="subject" value={emailSubject} />
              <div hidden>
                <label>
                  Trap for non-humans: <input name="first-name" />
                </label>
              </div>
              <div className="field">
                <div className="control">
                  <label htmlFor="name">
                    Your Name
                  </label>
                  <input
                    className="input"
                    type={'text'}
                    name={'name'}
                    id={'name'}
                    required={true}
                    onChange={e => handleSetEmailSubject(e.target.value)}
                  />
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <label htmlFor="email">
                    Your Email
                  </label>
                  <input
                    className="input"
                    type={'email'}
                    name={'email'}
                    id={'email'}
                    required={true}
                  />
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <label htmlFor="phone">
                    Phone Number
                  </label>
                  <input
                    className="input"
                    type={'tel'}
                    name={'phone'}
                    id={'phone'}
                    required={true}
                  />
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <label htmlFor="message">
                    Message
                  </label>
                  <textarea
                    className="textarea"
                    name={'message'}
                    id={'message'}
                    required={true}
                  />
                </div>
              </div>
              <div className="field">
                <button
                  className={`button is-secondary ${ContactFormStyles.submit || ''}`}
                  type="submit"
                >
                  Send Enquiry
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className={ContactFormStyles.mapHolder}>
          <Map className={ContactFormStyles.map} />

          <div className={ContactFormStyles.directions}>
            <strong>Collection Point:</strong>
            <p>Unit 5C, Kylemore Park North, Dublin 10 — D10 TX39</p>
            <p><b>Please do not come to the collection point until you receive confirmation that your order is ready for you.</b></p>
            <a href="https://goo.gl/maps/QAh3Sc3qN5TRBW4o6" target="_blank" rel="noreferrer noopener" className={`button is-secondary ${ContactFormStyles.viewOnMap}`}>View on Map</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactForm
