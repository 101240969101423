import React from 'react'

/**
 * Display the default map
 *
 * @param {string} className
 */
const Map = props => {
  return (
    <div className={`map ${props.className || ''}`}>
      <iframe
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2382.422860605792!2d-6.355274383846353!3d53.33568627997678!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48670cc2269d4f2f%3A0x951d4c3906766eb1!2sLeinster%20Facility%20Services!5e0!3m2!1sen!2sie!4v1666964811568!5m2!1sen!2sie"
        width="600"
        height="450"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
      ></iframe>
    </div>
  )
}

export default Map
