import React from 'react'
import Collapsible from 'react-collapsible'
import FaqSectionStyles from '../../styles/FaqSection.module.sass'

/**
 * Add an FAQ section
 *
 * @param {string}  [className]
 */
const FaqSection = props => {
  return (
    <div className={`${FaqSectionStyles.faqContainer || ''} ${props.className || ''}`}>
      <Collapsible trigger="What are the delivery costs?" open={true}>
        <p>The delivery charge is €7.99 per address. You can also collect your order from our warehouse in Kylemore Park North, Dublin 10.</p>
      </Collapsible>

      <Collapsible trigger="Where do you deliver?">
        <p>We offer nationwide delivery in Ireland. However, please note that some exceptions may apply for certain counties at certain times.</p>
      </Collapsible>

      <Collapsible trigger="Can you deliver on a certain date at a certain time?">
        <p>We offer delivery date and time options for Dublin, Wicklow and Kildare. You can choose a preferred date and time of day in your cart.</p>
        <p>Deliveries outside these areas generally take 1-5 working days. We will notify you in advance to ensure your recipient can accept the delivery.</p>
      </Collapsible>

      <Collapsible trigger="Are there any discounts for bulk orders?">
        <p>Yes! If you add 10 or more items of any type to your cart there will be a 10% discount applied automatically to your entire order.</p>
      </Collapsible>
    </div>
  )
}

export default FaqSection
