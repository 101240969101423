import { graphql, useStaticQuery } from 'gatsby'

const SiteMetadata = () => {
  const data = useStaticQuery(
    graphql`
      {
        site: site {
          siteMetadata {
            countiesWithTiming
            globalSchema
            minimumQuantityForVolumeDiscount
            maxProductQuantity
            nationwideDeliveryCharge
            phone
            title
          }
        }
      }
    `
  )

  return data.site.siteMetadata
}

export default SiteMetadata
