import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery, Link } from 'gatsby'
import { useSelector } from 'react-redux'
import { roundAndFix } from '../../scripts/helpers'
import Img from 'gatsby-image'
import { add } from 'cart-localstorage'
import SiteMetadata from '../SiteMetadata'
import SpecialNote from '../SpecialNote'
import ProductsStyles from '../../styles/Products.module.sass'

/**
 * Display a section with Products
 *
 * @param {string}  [type]
 * @param {string}  [className]
 * @param {string}  [title]
 * @param {string}  [intro]
 * @param {array}   [slugs] // slugs={['slug-1', 'slug-2']}
 * @param {boolean} [internationalOnly=false]
 */
const Products = (props) => {
  const data = useStaticQuery(posts)

  const { maxProductQuantity } = SiteMetadata()

  const selectedCurrency = useSelector((state) => state.currency)

  const Settings = require('../../data/site-settings.json')

  function addToOrderAction(id, title, price, quantity, intlAvailable) {
    add({ id: id, name: title, price: price, intlAvailable: intlAvailable }, quantity)

    // Reload the page to update the floating cart
    // TODO: Research using Redux for this purpose in the future
    window.location.reload()
  }

  // Create product ID maps
  let qtyArray = {}

  data.allMdx.edges.map(edge => {
    let id = edge.node.frontmatter.productID

    if (!qtyArray[id]) {
      qtyArray[id] = 1
    }

    return false
  })

  const [qty, setQty] = useState(qtyArray)

  // Increase/Decrease quantity using buttons
  function changeQty(operation, id) {
    // Prevent decreasing to 0 or increasing above maxProductQuantity
    if (
      (qty[id] <= 1 && operation === 'decrease') ||
      (qty[id] >= maxProductQuantity && operation === 'increase')
    ) {
      return false
    }

    // Clone and change array of quantities
    let _qty = qty

    if (operation === 'decrease') {
      _qty[id] = ~~_qty[id] - 1
    } else {
      _qty[id] = ~~_qty[id] + 1
    }

    // Replace quantity state with new array
    setQty({ ..._qty })
  }

  // Increase/Decrease quantity using number input
  function inputQty(input, id) {
    let _qty = qty

    if (input === '' || (~~input > 0 && ~~input <= maxProductQuantity)) {
      _qty[id] = ~~input
    }
    else if (input < 1) {
      _qty[id] = 1
    }
    else if (input > maxProductQuantity) {
      _qty[id] = maxProductQuantity
    }

    setQty({ ..._qty })
  }

  return (
    <div
      className={`force-full-width ${props.className || ''} ${ProductsStyles.products || ''}`}
    >
      <div className={`container is-semi-narrow ${ProductsStyles.container || ''}`}>
        {props.title && <h2 className={`title is-2 ${ProductsStyles.title || ''}`}>{props.title}</h2>}
        {props.intro && <div className={`is-narrow ${ProductsStyles.intro || ''}`} dangerouslySetInnerHTML={{ __html: props.intro }}></div>}
        {!props.hideMap && <SpecialNote />}

        <div className="columns is-multiline is-centered is-mobile">
          {data.allMdx.edges.map(edge => {
            // Filter by slugs
            if (props.slugs && !props.slugs.includes(edge.node.frontmatter.slug) ) {
              return false
            }
            if (props.internationalOnly && !edge.node.frontmatter.internationalDeliveryAvailable) {
              return false
            }
            else {
              let priceSource = edge.node.frontmatter.price

              if (selectedCurrency === 'GBP') {
                priceSource = edge.node.fields.priceGbp
              }
              else if (selectedCurrency === 'USD') {
                priceSource = edge.node.fields.priceUsd
              }
              else if (selectedCurrency === 'AUD') {
                priceSource = edge.node.fields.priceAud
              }

              const price = roundAndFix(priceSource)
              const adjustedPrice = price.toString().split('.')

              return (
                <div
                  key={edge.node.id}
                  className={`column is-one-third-tablet is-half-mobile ${ProductsStyles.productContainer || ''}`}
                >
                  <div className={ProductsStyles.product || ''}>
                    <div className={ProductsStyles.header || ''}>
                      <Link to={'/' + edge.node.frontmatter.slug}>
                        <Img
                          className={ProductsStyles.image || ''}
                          fluid={edge.node.fields.image.childImageSharp.fluid}
                        />
                        {edge.node.frontmatter.itemCount &&
                          <span className={ProductsStyles.itemCount || ''}>
                            {edge.node.frontmatter.itemCount} <i>items</i>
                          </span>
                        }
                        {edge.node.frontmatter.internationalDeliveryAvailable && <span className={ProductsStyles.intlDeliveryBadge || ''}>International Delivery</span>}
                      </Link>
                    </div>
                    <div className={ProductsStyles.vitals}>
                      <strong className={ProductsStyles.productTitle || ''}>
                        {edge.node.frontmatter.title}
                      </strong>
                      <p>{edge.node.frontmatter.shortDescription}</p>
                    </div>
                    <div className={ProductsStyles.addToOrder}>
                      <span className={ProductsStyles.price}>
                        <i dangerouslySetInnerHTML={{ __html: Settings['availableCurrencies'][selectedCurrency]['symbol'] }} />
                        <b><span>{
                          adjustedPrice[0]
                        }</span>
                        <small>{adjustedPrice[1]}</small></b>
                      </span>

                      <div>
                        <div className={ProductsStyles.quantityWidget}>
                          <input
                            type="text"
                            name="quantity"
                            value={qty[edge.node.frontmatter.productID]}
                            onChange={e => {
                              inputQty(
                                e.target.value,
                                edge.node.frontmatter.productID
                              )
                            }}
                          />
                          <span
                            className={`tag ${ProductsStyles.increase || ''}`}
                            onClick={() => {
                              changeQty('increase', edge.node.frontmatter.productID)
                            }}
                            onKeyDown={() => {
                              changeQty('increase', edge.node.frontmatter.productID)
                            }}
                            role="button"
                            tabIndex="0"
                          >
                            +
                          </span>
                          <span
                            className={`tag ${ProductsStyles.decrease || ''}`}
                            onClick={() => {
                              changeQty('decrease', edge.node.frontmatter.productID)
                            }}
                            onKeyDown={() => {
                              changeQty('decrease', edge.node.frontmatter.productID)
                            }}
                            role="button"
                            tabIndex="0"
                          >
                            –
                          </span>
                        </div>
                      </div>
                      <div className={ProductsStyles.actions}>
                        <Link 
                          to={'/' + edge.node.frontmatter.slug}
                          className={`button is-primary is-outlined ${ProductsStyles.viewContentsButton || ''}`}
                        >
                          View Contents
                        </Link>
                        <button
                          className={`button is-primary ${ProductsStyles.addToOrderButton || ''}`}
                          disabled={edge.node.frontmatter.stockStatus === 'outOfStock' ? true : false}
                          onClick={() => {
                            addToOrderAction(
                              edge.node.frontmatter.productID,
                              edge.node.frontmatter.title,
                              edge.node.frontmatter.price,
                              qty[edge.node.frontmatter.productID],
                              edge.node.frontmatter.internationalDeliveryAvailable ? edge.node.frontmatter.internationalDeliveryAvailable : false
                            )
                          }}
                        >
                          {edge.node.frontmatter.stockStatus === 'outOfStock' ? 'Out of Stock' : 'Add to Order'}
                        </button>
                      </div>
                    </div>
                  </div>
                  { edge.node.frontmatter.schema ?
                    <Helmet>
                      <script type="application/ld+json">{edge.node.frontmatter.schema}</script>
                    </Helmet>
                    : null 
                  }
                </div>
              )
            }
          })}
        </div>
      </div>
    </div>
  )
}

export default Products

export const posts = graphql`
  {
    allMdx(filter: {frontmatter: {templateKey: {eq: "product"}}}, sort: {fields: frontmatter___productID, order: ASC}) {
      edges {
        node {
          id
          fields {
            slug
            image {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            priceGbp
            priceUsd
            priceAud
          }
          frontmatter {
            itemCount
            price
            productType
            productID
            schema
            shortDescription
            internationalDeliveryAvailable
            slug
            stockStatus
            title
          }
        }
      }
    }
  }
`
