import React from 'react'
import SpecialNoteStyles from '../styles/SpecialNote.module.sass'

/**
 * Add a special note
 */
const SpecialNote = () => {
  return <div className={`special-note ${SpecialNoteStyles.specialNote}`}>Add multiple hampers or boxes to your cart - you can send them to different addresses!</div>
}

export default SpecialNote
