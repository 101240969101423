import React from 'react'
import { Link } from 'gatsby'
import Cta from './shortcodes/Cta'
import logo from '../../static/media/logo.svg'
import SiteMetadata from './SiteMetadata'
import FooterStyles from '../styles/Footer.module.sass'

/**
 * Display the global footer
 */
const Footer = props => {
  const { title } = SiteMetadata()

  return (
    <footer className={`footer ${FooterStyles.footer || ''}`}>
      <div className="container has-text-centered is-semi-narrow">
        <Cta text="View Hampers" link="/#products" buttonClassName="is-secondary" />
        <p>
          <Link
            to="/"
            className={FooterStyles.logoHolder || ''}
            title="Logo"
          >
            <img
              src={logo}
              alt={title}
              className={`${FooterStyles.logo || ''}`}
            />
          </Link>
          Copyright{' '}
          <span className={FooterStyles.currentYear || ''}>{new Date().getFullYear()}</span>{' '}
          <strong>{title}</strong>. All rights reserved.{' '}
          <span className={FooterStyles.shamrock || ''}>
            From Dublin with love
          </span>
        </p>
      </div>
    </footer>
  )
}

export default Footer
