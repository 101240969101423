import React from 'react'
import { Link } from 'gatsby'
import { useSelector } from 'react-redux'
import { list, total } from 'cart-localstorage'
import { roundAndFix } from '../scripts/helpers'
import CartWidgetStyles from '../styles/CartWidget.module.sass'

const CartWidget = () => {
  let cart = typeof window !== 'undefined' ? list() : null
  let totalQuantity = calculateTotalQuantity()

  const selectedCurrency = useSelector((state) => state.currency)

  const Settings = require('../data/site-settings.json')

  // Prevent Gatsby from prerendering this component
  if (typeof window == 'undefined') {
    return false
  }

  const conversionRateGbp = Settings.availableCurrencies.GBP.conversionRate
  const conversionRateUsd = Settings.availableCurrencies.USD.conversionRate
  const conversionRateAud = Settings.availableCurrencies.AUD.conversionRate

  let convertedTotal = total()

  if (selectedCurrency === 'GBP') {
    convertedTotal = Math.ceil(convertedTotal / conversionRateGbp * 1.02)
  }
  else if (selectedCurrency === 'USD') {
    convertedTotal = Math.ceil(convertedTotal / conversionRateUsd * 1.02)
  }
  else if (selectedCurrency === 'AUD') {
    convertedTotal = Math.ceil(convertedTotal / conversionRateAud * 1.02)
  }

  function calculateTotalQuantity(list) {
    if (list && list.length) {
      let initialQuantity = 0

      list.map(item => {
        initialQuantity = initialQuantity + item.quantity

        return false
      })

      return initialQuantity
    } else if (cart && cart.length) {
      let initialQuantity = 0

      cart.map(item => {
        initialQuantity = initialQuantity + item.quantity

        return false
      })

      return initialQuantity
    } else {
      return 0
    }
  }

  if (cart && cart.length) {
    return (
      <Link 
        className={`cart-widget ${CartWidgetStyles.cart || ''}`}
        to="/checkout/"
      >
        <header className={CartWidgetStyles.header || ''}>
          <div>
            <b>My Order</b> <i>(<b dangerouslySetInnerHTML={{ __html: Settings['availableCurrencies'][selectedCurrency]['symbol'] }} />{roundAndFix(convertedTotal) })</i>
            <br />
            {(() => {
              if (totalQuantity === 1) {
                return (
                  <span>
                    1 product
                  </span>
                )
              } else if (totalQuantity > 1) {
                return (
                  <span>
                    {totalQuantity} products
                  </span>
                )
              }
            })()}
            <em>Checkout</em>
          </div>
        </header>
      </Link>
    )
  } else {
    return <div className={`cart-widget ${CartWidgetStyles.cartEmpty || ''}`}>Your cart is empty.</div>
  }
}

export default CartWidget
