import React from 'react'
import { Link } from 'gatsby'
import SiteMetadata from '../SiteMetadata'
import CtaStyles from '../../styles/Cta.module.sass'

/**
 * Display a call-to-action section
 *
 * @param {string}  [text]
 * @param {string}  [link]
 * @param {string}  [before]
 * @param {string}  [className]
 * @param {string}  [buttonClassName]
 * @param {boolean} [isPhone=false]
 */
const Cta = props => {
  const { phone } = SiteMetadata()

  let text = 'Get a Quote'
  let link = '/contact/'

  if (props.text) {
    text = props.text
  }

  if (props.link) {
    link = props.link
  }

  if (props.isPhone === true || props.isPhone === 'true') {
    text = phone
    link = 'tel:' + phone

    return (
      <div className={`cta ${CtaStyles.cta} ${props.className || ''} ${props.buttonClassName ? props.buttonClassName : 'is-primary'}`}>
        {props.before ? <em>{props.before}</em> : null}
        <a
          href={link}
          className={`button ${props.buttonClassName ? props.buttonClassName : 'is-primary'} ${CtaStyles.button || ''} ${(props.isPhone === true || props.isPhone === 'true') ? 'icon-phone-light' : ''}`}
        >
          {text}
        </a>
      </div>
    )
  }

  return (
    <div className={`cta ${CtaStyles.cta} ${props.className || ''} ${props.buttonClassName ? props.buttonClassName : 'is-primary'}`}>
      {props.before ? <em>{props.before}</em> : null}
      <Link
        to={link}
        className={`button ${props.buttonClassName ? props.buttonClassName : 'is-primary'} ${CtaStyles.button || ''} ${(props.isPhone === true || props.isPhone === 'true') ? 'icon-phone-light' : ''}`}
      >
        {text}
      </Link>
    </div>
  )
}

export default Cta
