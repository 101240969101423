import React from 'react'
import Img from 'gatsby-image'
import Button from './shortcodes/Button'
import HeroStyles from '../styles/Hero.module.sass'

/**
 * Display a custom hero image
 *
 * @param {string}  [aboveTitle]
 * @param {string}  [afterCta]
 * @param {object}  [image]
 * @param {string}  [imageAlt]
 * @param {string}  title
 * @param {string}  [subtitle]
 * @param {boolean} [showCta=true]
 * @param {string}  [ctaText]
 * @param {string}  [ctaUrl]
 */
const Hero = (props) => {
  return (
    <section
      className={`hero ${HeroStyles.hero} ${props.image ? 'with-image' : ''}`}
    >
      {props.image ? (
        props.image.childImageSharp ? (
          <div className={`hero-image ${HeroStyles.image || ''}`}>
            <Img
              fluid={props.image.childImageSharp.fluid}
              alt={props.imageAlt || ''}
              title={props.imageAlt || ''}
            />
          </div>
        ) : (
          <div className={`hero-image ${HeroStyles.image || ''}`}>
            <img
              src={props.image}
              alt={props.imageAlt || ''}
              title={props.imageAlt || ''}
            />
          </div>
        )
      ) : null}
      <div className={`container ${HeroStyles.container || ''}`}>
        <div className={`hero-body ${HeroStyles.heroBody || ''}`}>
          <div className={HeroStyles.titleHolder}>
            {props.aboveTitle 
              && <span className={HeroStyles.aboveTitle || ''}>{props.aboveTitle} </span>
            }
            <h1
              className={`title is-1 ${HeroStyles.title || ''}`}
              dangerouslySetInnerHTML={{ __html: props.title }}
            />
          </div>
          
          {props.subtitle && (
            <p
              className={`subtitle ${HeroStyles.subtitle || ''}`}
              dangerouslySetInnerHTML={{ __html: props.subtitle }}
            />
          )}

          {props.showCta !== false && props.showCta !== 'false' && (
            <div className={HeroStyles.buttonAndAfterCta || ''}>
              <Button
                url={props.ctaUrl || '/contact/'}
                text={props.ctaText || 'Get a Quote'}
                type={props.image && 'is-primary'}
              />
              {props.afterCta && (
                <span
                  className={HeroStyles.afterCta || ''}
                  dangerouslySetInnerHTML={{ __html: props.afterCta }}
                />
              )}
            </div>
          )}

          {props.children}
        </div>
      </div>
    </section>
  )
}

export default Hero
