import React, { useState } from 'react'
import { Link } from 'gatsby'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrency } from '../state/slices/currencySlice'
import { changeDynamicData } from '../scripts/helpers'
import SiteMetadata from './SiteMetadata'
import logo from '../../static/media/logo.svg'
import MainMenu from '../data/menus.json'
import Phone from './shortcodes/Phone'
import NavbarStyles from '../styles/Navbar.module.sass'

/**
 * Display the global header
 * 
 * @param {boolean} [solid=false]
 */
const Navbar = props => {
  const { title, phone } = SiteMetadata()

  const dispatch = useDispatch()

  const selectedCurrency = useSelector((state) => state.currency)

  const currencySymbols = {
    'EUR': '&euro;',
    'GBP': '&pound;',
    'USD': '&dollar;',
    'AUD': 'AU&dollar;'
  }

  const handleCurrencyChange = (newCurrency) => {
    dispatch(setCurrency(newCurrency))

    changeDynamicData(newCurrency)
  }

  // Grab menu from JSON using this slug
  const menuUsedSlug = 'main'

  const [navbarState, setNavbarState] = useState(false)
  const [currencySwitcherOpen, setCurrencySwitcherOpen] = useState(false)

  const toggleState = (newState = !navbarState) => {
    setNavbarState(newState)
  }

  const toggleCurrencySwitcher = (newState = !currencySwitcherOpen) => {
    setCurrencySwitcherOpen(newState)
  }

  return (
    <nav
      className={`navbar is-transparent ${NavbarStyles.navbar || ''} ${props.solid ? 'is-solid' : 'is-not-solid'}`}
      role="navigation"
      aria-label="main-navigation"
    >
      <div className="container is-semi-narrow">
        <div className={`navbar-brand ${NavbarStyles.navbarBrand || ''}`}>
          <Link
            to="/"
            className={`navbar-item ${NavbarStyles.logoHolder || ''}`}
            title="Logo"
          >
            <img
              src={logo}
              alt={title}
              className={`${NavbarStyles.logo || ''}`}
            />
          </Link>

          {/* Hamburger menu */}
          <span
            onClick={() => toggleState()}
            onKeyDown={() => toggleState()}
            className={`navbar-burger burger ${
              navbarState ? 'is-active' : '' || ''
            }`}
            role="button"
            tabIndex="0"
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </span>

          {/* Mobile Call Button */}
          <a
            href={`tel:${phone}`}
            className={`mobile-phone-button is-hidden-desktop ${NavbarStyles.mobilePhoneButton ||
              ''}`}
          >
            Call
          </a>

          <div className={`dropdown is-hidden-desktop ${NavbarStyles.currencyChanger || ''} ${NavbarStyles.currencyMobileOnly || ''} ${currencySwitcherOpen && 'is-active'}`}>
            <div
              className='dropdown-trigger'
              onClick={() => toggleCurrencySwitcher()}
            >
              <span>
                <img src={`/media/icon-flag-${selectedCurrency}.svg`} alt={selectedCurrency} />
                <i dangerouslySetInnerHTML={{ __html: currencySymbols[selectedCurrency] }} /> <em>({selectedCurrency})</em>
              </span>
              <em>Change</em>
            </div>
            <div className='dropdown-menu' role='menu'>
              <div className='dropdown-content'>
                <span
                  className='dropdown-item'
                  onClick={() => {
                    handleCurrencyChange('EUR')
                    toggleCurrencySwitcher()
                  }}
                >
                  <img src={`/media/icon-flag-EUR.svg`} alt='EUR' /><i>EUR</i>
                </span>
                <span
                  className='dropdown-item'
                  onClick={() => {
                    handleCurrencyChange('GBP')
                    toggleCurrencySwitcher()
                  }}
                >
                  <img src={`/media/icon-flag-GBP.svg`} alt='GBP' /><i>GBP</i>
                </span>
                <span
                  className='dropdown-item'
                  onClick={() => {
                    handleCurrencyChange('USD')
                    toggleCurrencySwitcher()
                  }}
                >
                  <img src={`/media/icon-flag-USD.svg`} alt='USD' /><i>USD</i>
                </span>
                <span
                  className='dropdown-item'
                  onClick={() => {
                    handleCurrencyChange('AUD')
                    toggleCurrencySwitcher()
                  }}
                >
                  <img src={`/media/icon-flag-AUD.svg`} alt='AUD' /><i>AUD</i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          id="navMenu"
          className={`navbar-menu ${navbarState ? 'is-active' : ''} ${NavbarStyles.menu || ''}`}
        >
          <div className={`navbar-start ${NavbarStyles.navbarStart || ''}`}>
            {MainMenu.menus
              .find(menu => menu.title === menuUsedSlug)
              .links.map(({ text, link, links }) =>
                links ? (
                  <div
                    key={text + link}
                    className="navbar-item has-dropdown is-hoverable"
                  >
                    <Link
                      key={text}
                      to={link}
                      className="navbar-link"
                      activeClassName="is-active"
                      onClick={() => {
                        setNavbarState(false)
                      }}
                    >
                      {text}
                    </Link>

                    <div
                      className={`navbar-dropdown ${NavbarStyles.navbarDropdown ||
                        ''}`}
                    >
                      {links.map(({ text, link }) => (
                        <Link
                          key={text}
                          to={link}
                          className="navbar-item"
                          activeClassName="is-active"
                          onClick={() => {
                            setNavbarState(false)
                          }}
                        >
                          {text}
                        </Link>
                      ))}
                    </div>
                  </div>
                ) : (
                  <Link
                    key={text}
                    to={link}
                    className="navbar-item"
                    activeClassName="is-active"
                    onClick={() => {
                      setNavbarState(false)
                    }}
                  >
                    {text}
                  </Link>
                )
              )}
          </div>
          <div className={`navbar-end ${NavbarStyles.navbarEnd || ''}`}>
            <div className={`dropdown is-hidden-touch ${NavbarStyles.currencyChanger || ''} ${currencySwitcherOpen && 'is-active'}`}>
              <div
                className='dropdown-trigger'
                onClick={() => toggleCurrencySwitcher()}
              >
                <span>
                  <img src={`/media/icon-flag-${selectedCurrency}.svg`} alt={selectedCurrency} />
                  <i dangerouslySetInnerHTML={{ __html: currencySymbols[selectedCurrency] }} /> <em>({selectedCurrency})</em>
                </span>
                <em>Change</em>
              </div>
              <div className='dropdown-menu' role='menu'>
                <div className='dropdown-content'>
                  <span
                    className='dropdown-item'
                    onClick={() => {
                      handleCurrencyChange('EUR')
                      toggleCurrencySwitcher()
                    }}
                  >
                    <img src={`/media/icon-flag-EUR.svg`} alt='EUR' /><i>EUR</i>
                  </span>
                  <span
                    className='dropdown-item'
                    onClick={() => {
                      handleCurrencyChange('GBP')
                      toggleCurrencySwitcher()
                    }}
                  >
                    <img src={`/media/icon-flag-GBP.svg`} alt='GBP' /><i>GBP</i>
                  </span>
                  <span
                    className='dropdown-item'
                    onClick={() => {
                      handleCurrencyChange('USD')
                      toggleCurrencySwitcher()
                    }}
                  >
                    <img src={`/media/icon-flag-USD.svg`} alt='USD' /><i>USD</i>
                  </span>
                  <span
                    className='dropdown-item'
                    onClick={() => {
                      handleCurrencyChange('AUD')
                      toggleCurrencySwitcher()
                    }}
                  >
                    <img src={`/media/icon-flag-AUD.svg`} alt='AUD' /><i>AUD</i>
                  </span>
                </div>
              </div>
            </div>
            <Phone className={`button is-soft-secondary ${NavbarStyles.phone || ''}`} />
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
